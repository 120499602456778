import React from 'react'

const Footer = () => {
  return (
    <footer id="newsletter">
      <div className="container" style={{margin:'0 auto'}}>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="section-heading">
              <h4>Contact Us</h4>
              <div className="row">
                <div className="col-md-4 copyright-text mt-5-custom"> <p>+962 7993 84891</p></div>
                <div className="col-md-4 copyright-text"> <p>Searaw.jo</p></div>
                <div className="col-md-4 copyright-text"> <p>WP : +962 7993 84891</p></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="copyright-text">
              <p>
                Copyright © 2023 Sewara.Jo Company. All Rights Reserved.
                {/* <br />
                Design:
                <a href="" target="_blank" title="css templates">
                  A&M
                </a> */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer