import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./index.css";
import { usePagination, useTable } from "react-table";
import Spinner from "../../components/Spinner";

export default function ShowAllQR() {
  const [data, setData] = useState([]);
  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = useMemo(
    () => [
      { Header: "QR Code", accessor: "qrCode" },
      { Header: "Image", accessor: "image" },
    ],
    []
  );

  const handleNextPage = (lastPage) => {
    if (canNextPage) {
      if (lastPage) {
        setCurrentPage(lastPage);
        // nextPage();
        nextPage(lastPage);

        // gotoPage(lastPage)
        getAllQRWithCallback(); // Fetch data for the new page
      } else {
        setIsLoading(true);
        nextPage();

        setCurrentPage(currentPage + 1);

        getAllQRWithCallback(); // Fetch data for the new page
      }
    }
  };
  const handlePreviousPage = (firstPage) => {
    if (canPreviousPage) {
      setIsLoading(true);
      previousPage();
      setCurrentPage(currentPage - 1);
      if (firstPage) {
        setCurrentPage(1);
        getAllQRWithCallback(); // Fetch data for the new page
      }
      getAllQRWithCallback(); // Fetch data for the new page
    }
  };
  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(() => {
    return data;
  }, [data]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: { pageIndex: 0, pageSize: info.pageSize || 10 }, // Set the pageSize from info or default to 10
      manualPagination: true,
      pageCount: info.totalPages, // Set the pageCount here
    },
    usePagination
  );
  const getAllQR = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    console.log("currentPage getAllQR:>> ", currentPage);
    fetch(
      `${process.env.REACT_APP_API_URL}qr?page=${currentPage}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        setInfo(result);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getAllQR();
  }, []);
  const getAllQRWithCallback = useCallback(() => {
    setIsLoading(true);
    getAllQR(currentPage);
  }, [currentPage]);
  useEffect(() => {
    getAllQRWithCallback();
  }, [getAllQRWithCallback]);
  return (
    <div>
      <div className="container">
        <h4> Show All QR</h4>
        <p> Total Items : {info?.totalItems}</p>
        {data.length ? (
          <>
            <table {...getTableProps()} className="table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>
                          {cell.column.id === "qrCode"
                            ? cell.value // Render value if present
                            : null}
                          {cell.column.id === "image" ? (
                            <img
                              src={cell.value}
                              alt="QR Code"
                              style={{
                                maxWidth: "100%",
                                height: "150px",
                                width: "150px",
                              }}
                            />
                          ) : null}
                          {/* Show loading message or handle appropriately */}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* Pagination controls */}
            <div className="pagination-table">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageCount}
                </strong>{" "}
              </span>
              {/* <button
                onClick={() => {
                  // gotoPage(0);
                  const page = 1;
                  handlePreviousPage(page);
                }}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "} */}
              <button
                onClick={() => {
                  // gotoPage(0);
                  handlePreviousPage();
                }}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                onClick={() => {
                  handleNextPage();
                }}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              {/* <button
                onClick={() => {
                  // gotoPage(pageCount - 1);
                  handleNextPage(info.totalPages);
                }}
                disabled={!canNextPage}
              >
                {">>"}
              </button> */}
            </div>
            {/* <div>
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                Previous Page
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next Page
              </button>
            </div> */}
          </>
        ) : (
          <Spinner />
        )}
        {/* {isLoading ? <Spinner /> : null} */}
      </div>
    </div>
  );
}

