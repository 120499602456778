import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import "../components/Sidebar/Sidebar.css";
import Header from "../components/header/header";
import Wrapper from "../components/Wrapper/Wrapper";
import "../components/Wrapper/index.css";
import Spinner from "../components/Spinner";

const HomePageNew = () => {
  const [staticCount, setStaticCount] = useState(null);
  const [loading, setLoading] = useState(true);

  const getStaticCount = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_API_URL}dashboard/count`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log('object result:>> ', result);
        setStaticCount(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getStaticCount();
  }, []);
  return <>{loading ? <Spinner /> : <Wrapper data={staticCount} />}</>;
};

export default HomePageNew;
