import React from "react";
import "./index.css";
import { useNavigate } from 'react-router-dom'
export default function Login() {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior

    if (username && password) {
      console.log("Logging in with:", { username, password });
      LoginFunction(username, password);
    } else {
      window.alert("error");
    }
    // Add your login logic here
  };
  const LoginFunction = (email, password) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
      password: password,
    });
    console.log("raw :>> ", raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_URL}admin/login`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("result login", result);
        if(result.token){
          localStorage.setItem("auth_token", result.token);
          navigate('/dashboard', { replace: true });
        }else{
          window.alert(result.error)

        }
      })
      .catch((error) => {
        console.log("error login", error);
        window.alert(error)
      });
  };
  return (
    <div className="main">
      <div className="container">
        <div className="card-login">
          <div className="imaga-card">
            <img
              src="assets/images/logonew.png"
              alt="Sewara logo"
              className="img-card"
            />
          </div>
          <div className="form-login">
            <form>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="username"
                  value={username}
                  onChange={handleUsernameChange}
                  aria-describedby="emailHelp"
                />
                <div id="emailHelp" class="form-text">
                </div>
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  Password
                </label>
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>

              <button
                onClick={(e) => handleLogin(e)}
                type="submit"
                class="btn btn-login"
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

