import React from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import "../components/Sidebar/Sidebar.css";
import Header from "../components/header/header";

const LayoutDashboard = () => {
  const onClick = () => {};
  return (
    <div className="page flex">
      <Sidebar onClick={onClick} />
      <div className="content w-full">
        <Header />

        <h1 className="relative my-5 ml-4 Title-head">Dashboard</h1>
        <Outlet />
      </div>
    </div>
  );
};

export default LayoutDashboard;
