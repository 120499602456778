import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./index.css";
import { usePagination, useTable } from "react-table";
import { useReactToPrint } from "react-to-print";

import Spinner from "../../components/Spinner";
export default function ShowQRNotUsed() {
  const [data, setData] = useState([]);
  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const tableRef = React.useRef();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const columns = useMemo(
    () => [
      { Header: "QR Code", accessor: "qrCode" },
      { Header: "Image", accessor: "image" },
    ],
    []
  );
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });
  const handlePageChange = (newPage) => {
    setIsLoading(true);
    getQRUsed(newPage);
  };
  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(() => data, [data]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: { pageIndex: 0, pageSize: info.pageSize || 10 }, // Set the pageSize from info or default to 10
      manualPagination: true,
      pageCount: info.totalPages, // Set the pageCount here
    },

    usePagination
  );
  const getQRUsed = (newPage = 1) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_API_URL}not/used?page=${currentPage}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setData(result.data);
        setInfo(result);
        setTotalPages(result.totalPages);
        setTotalItems(result.totalItems);
      })
      .catch((error) => console.log("error", error));
  };

  const handleNextPage = (lastPage) => {
    if (canNextPage) {
      if (lastPage) {
        setCurrentPage(lastPage);
        // nextPage();
        nextPage(lastPage);

        // gotoPage(lastPage)
        getAllQRWithCallback(); // Fetch data for the new page
      } else {
        setIsLoading(true);
        nextPage();

        setCurrentPage(currentPage + 1);

        getAllQRWithCallback(); // Fetch data for the new page
      }
    }
  };
  const handlePreviousPage = (firstPage) => {
    if (canPreviousPage) {
      setIsLoading(true);
      previousPage();
      setCurrentPage(currentPage - 1);
      if (firstPage) {
        setCurrentPage(1);
        getAllQRWithCallback(); // Fetch data for the new page
      }
      getAllQRWithCallback(); // Fetch data for the new page
    }
  };
  useEffect(() => {
    getQRUsed();
  }, [currentPage]);
  const getAllQRWithCallback = useCallback(() => {
    setIsLoading(true);
    getQRUsed(currentPage);
  }, [currentPage]);
  useEffect(() => {
    getAllQRWithCallback();
  }, [getAllQRWithCallback]);
  return (
    <div>
      <div className="container">
      <h4> Show All QR Not Used</h4>

        <p> Total Items : {info?.totatItems}</p>
        {data.length ? (
          <button
            onClick={(e) => handlePrint()}
            type="submit"
            class="btn-create"
          >
            Print Table to PDF{" "}
          </button>
        ) : null}
        {data.length ? (
          <>
            <table {...getTableProps()} ref={tableRef} className="table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>
                          {cell.column.id === "qrCode"
                            ? cell.value // Render value if present
                            : null}
                          {cell.column.id === "image" ? (
                            <img
                              src={cell.value}
                              alt="QR Code"
                              style={{
                                maxWidth: "100%",
                                height: "150px",
                                width: "150px",
                              }}
                            />
                          ) : null}
                          {/* Show loading message or handle appropriately */}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* Pagination controls */}
            {/* Pagination controls */}
            <div className="pagination-table">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageCount}
                </strong>{" "}
              </span>
              {/* <button
                onClick={() => {
                  // gotoPage(0);
                  const page = 1;
                  handlePreviousPage(page);
                }}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "} */}
              <button
                onClick={() => {
                  // gotoPage(0);
                  handlePreviousPage();
                }}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                onClick={() => {
                  handleNextPage();
                }}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              {/* <button
                onClick={() => {
                  // gotoPage(pageCount - 1);
                  handleNextPage(info.totalPages);
                }}
                disabled={!canNextPage}
              >
                {">>"}
              </button> */}
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}

