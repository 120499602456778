import React from "react";
import "./index.css";
import { FaDollarSign, FaSalesforce } from "react-icons/fa";
import { BsDiagram2Fill, BsListCheck } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
const Wrapper = ({data}) => {
  console.log('staticCount :>> ', data);
  return (
    <div className=" grid gap-5 px-3">
      {/* <div className="welcome bg-white rounded-xl ">
        <div className="intro p-5 flex justify-between bg-[#eee]">
          <div>
            <h2 className="m-0">Welcome</h2>
            <p className="mt-[5px] text-[#888] ">Ahmad</p>
          </div>
          <img
            className="hide-mobile"
            alt=""
            src={require("../../assets/imgs/welcome.png")}
          />
        </div>
        <img
          className="avatar"
          alt=""
          src={require("../../assets/imgs/avatar.png")}
        />

        <div className="body text-center flex p-5 mt-5 mb-5 ">
          <div>
            Ahmad Alghalban{" "}
            <span className="block text-[#888] mt-3 text-[14px] ">
              Developer
            </span>
          </div>
          <div>
            800
            <span className="block text-[#888] mt-3 text-[14px] ">project</span>
          </div>
          <div>
            8500
            <span className="block text-[#888] mt-3 text-[14px] ">Earned</span>
          </div>
        </div>
        <a
          href="/profile"
          className="visit btn-shape block bg-[#0075ff] text-[14px] text-white w-fit"
        >
          Profile
        </a>
      </div> */}
      {/* <div className="welcome bg-white rounded-xl ">
        <h2>Welcome</h2>
      </div> */}
      {/* Start quick */}
      {/* <div className="quick-draft p-5 bg-white rounded-[10px]">
        <h2 className="mt-0 mb-10 ">Quick Draft</h2>
        <h2 className="mt-0 mb-5 text-[#888] text-[15px]">
          Write A Draft For your Ideas
        </h2>
        <form>
          <input
            placeholder="title"
            type="text"
            className="block mb-5 w-full p-5 border-none bg-[#eee] rounded-[6px]"
          />
          <textarea
            className="block mb-5 w-full p-5 border-none bg-[#eee] rounded-[6px] "
            placeholder="Your Thought"
          />
          <input
            type="submit"
            className="save block text-[14px] bg-[var(--blue-color)] border-none w-fit text-white btn-shape"
            value="save"
          />
        </form>
      </div> */}
      {/* Start Targets */}
      {/* <div className="targets p-5 bg-white rounded-[10px]">
        <h2 className="mt-0 mb-3">Yearly Targets </h2>
        <p className="mt-0 mb-5 text-[15px] text-[#888]">Targets of The Year</p>
        <div className="targets-row blue  mb-5 blue flex items-center ">
          <div className="icon flex justify-center items-center">
            <FaDollarSign className="c-blue" />
          </div>
          <div className="details">
            <span className="text-[14px] text-[#888]">Money</span>
            <span className="block mt-1 mb-3 ">$20.000</span>
            <div className="progress  relative">
              <span className="bg-[#0075ff]  blue  " style={{ width: "80%" }}>
                <span className="bg-[#0075ff]">40%</span>
              </span>
            </div>
          </div>
        </div>
        <div className="targets-row blue  mb-5 orange flex items-center ">
          <div className="icon flex justify-center items-center">
            <FaDollarSign className="c-orange" />
          </div>
          <div className="details">
            <span className="text-[14px] text-[#888]">Money</span>
            <span className="block mt-1 mb-3 ">$20.000</span>
            <div className="progress  relative">
              <span className="bg-orange-600 orange  " style={{ width: "40%" }}>
                <span className="bg-orange-600">40%</span>
              </span>
            </div>
          </div>
        </div>
        <div className="targets-row blue  mb-5 blue flex items-center ">
          <div className="icon flex justify-center items-center">
            <FaDollarSign className="c-blue" />
          </div>
          <div className="details">
            <span className="text-[14px] text-[#888]">Money</span>
            <span className="block mt-1 mb-3 ">$20.000</span>
            <div className="progress  relative">
              <span className="bg-[#0075ff]  blue  " style={{ width: "80%" }}>
                <span className="bg-[#0075ff]">40%</span>
              </span>
            </div>
          </div>
        </div>
      </div> */}
      {/* Start Tickets */}
      <div className="tickets p-5 bg-white rounded-xl">
        <h2 className="mt-0 mb-3">System Statics</h2>
        <p className="mt-0 mb-5 text-[15px] text-[#888]">
        Here to find out most of the numbers
        </p>
        <div className="flex justify-center text-center gap-5 flex-wrap relative">
          <div className="box-tickets p-3 rounded-[10px] text-[#888] ">
            <BsListCheck className="mb-3 text-[#B104A0] text-[40px] m-auto" />
            <span className="block text-black font-bold text-[25px] mb-1">
              {data?.qrCount}
            </span>
            All QRs
          </div>
          <div className="box-tickets p-3 rounded-[10px] text-[#888] ">
            <BsDiagram2Fill className="mb-3 text-[#DB016A] text-[40px] m-auto" />
            <span className="block text-black font-bold text-[25px] mb-1">
            {data?.qrUsedCount}
            </span>
            QRs Used
          </div>
          <div className="box-tickets p-3 rounded-[10px] text-[#888] ">
            <BsListCheck className="mb-3 text-[#7008F1] text-[40px] m-auto" />
            <span className="block text-black font-bold text-[25px] mb-1">
            {data?.qrNotUsedCount}
            </span>
            QRs Not Used

          </div>
          <div className="box-tickets p-3 rounded-[10px] text-[#888] ">
            <BsDiagram2Fill className="mb-3 text-[#AB04A7] text-[40px] m-auto" />
            <span className="block text-black font-bold text-[25px] mb-1">
            {data?.userCount}
            </span>
            Users
          </div>
          <div className="box-tickets p-3 rounded-[10px] text-[#888] ">
            <BsListCheck className="mb-3 text-[#9606C2] text-[40px] m-auto" />
            <span className="block text-black font-bold text-[25px] mb-1">
            {data?.adminCount}
            </span>
            Admins
          </div>
        </div>
      </div>
      {/* Last News  */}
      {/* <div className="last-news p-5 bg-white rounded-xl ">
        <h2 className="mt-0 mb-3">Last News</h2>
        <div className="new-row flex items-center">
          <img src={require("../../assets/imgs/news-04.png")} alt="" />
          <div className="info-news">
            <h3>Created SASS Section</h3>
            <p className="m-0 text-[14px] text-[#888]">
              Created SASS Section Examples
            </p>
          </div>
          <div className="btn-shape bg-[#eee] text-[13px] lable-news">
            1 Days Ago
          </div>
        </div>
        <div className="new-row flex items-center">
          <img src={require("../../assets/imgs/news-03.png")} alt="" />
          <div className="info-news">
            <h3>Created SASS Section</h3>
            <p className="m-0 text-[14px] text-[#888]">
              Created SASS Section Examples
            </p>
          </div>
          <div className="btn-shape bg-[#eee] text-[13px] lable-news">
            4 Days Ago
          </div>
        </div>
        <div className="new-row flex items-center">
          <img src={require("../../assets/imgs/news-02.png")} alt="" />
          <div className="info-news">
            <h3>Created SASS Section</h3>
            <p className="m-0 text-[14px] text-[#888]">
              Created SASS Section Examples
            </p>
          </div>
          <div className="btn-shape bg-[#eee] text-[13px] lable-news">
            8 Days Ago
          </div>
        </div>
        <div className="new-row flex items-center">
          <img src={require("../../assets/imgs/news-01.png")} alt="" />
          <div className="info-news">
            <h3>Created SASS Section</h3>
            <p className="m-0 text-[14px] text-[#888]">
              Created SASS Section Examples
            </p>
          </div>
          <div className="btn-shape bg-[#eee] text-[13px] lable-news">
            3 Days Ago
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Wrapper;
