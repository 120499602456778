import React, { useState } from "react";
import { AiFillBell } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { AiOutlineLeftSquare } from "react-icons/ai";
import "../Sidebar/Sidebar.css";
const Header = () => {
  const [showBox, setShowBox] = useState(false);
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("auth_token");
    navigate('/login');
  };
  return (
    <div className="head bg-white p-4 between-flex">
      {/* <input className="p-3" type="text" placeholder="Type A Keyword" /> */}
      <div className=" relative"></div>
      <div
        className="icons flex items-center cursor-pointer relative w-20"
        onClick={() => setShowBox(!showBox)}
      >
        {/* <span className="notification relative fa-lg">
        <AiFillBell />
      </span> */}
        <img src={require("../../assets/imgs/avatar.png")} alt="" />
        {showBox ? (
          <div
            className="card-img-head top-100  w-full absolute"
            onClick={() => logout()}
          >
            <div className="options-logout py-2">
              <i class="fa fa-power-off"></i> Logout
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
