import React from "react";
import logo from "../../assets/icons/logo.png";
import hand from "../../assets/icons/hand-illustration.png";
import GP from '../../assets/imgs/mobile/google_stores_buttons.png'
import HW from '../../assets/imgs/mobile/hw_stores_buttons.png'
import AP from '../../assets/imgs/mobile/apple_stores_buttons.png'
import Footer from "../../components/Root/Footer";
const Home = () => {
  return (
    <>
          <div style={{ position: "relative" }}>
      <div
        className="main-banner wow fadeIn"
        id="top"
        data-wow-duration="1s"
        data-wow-delay="0.5s"
      >
        <div className="container" style={{margin:'0 auto'}}>
          <div className="row ">
            <div className="col-lg-12">
              <div className="row ">
                <div className="col-lg-6 align-self-center">
                  <div
                    className="left-content show-up header-text wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <h2> Sewara Qr_Code </h2>
                        <p className="desc-home">
                          It is a digital tool that enables to know the details
                          related to children in terms of name, age, grade,
                          health status, blood type, home address, reference
                          phone number, type of medicines related to the child,
                          the initial procedures followed in the event of
                          seizures in the child, and this helps reduce the rate
                          of error in dealing with children and increases the
                          possibility of Ease of identifying the child in
                          schools and public places.
                        </p>
                        <p className="desc-home">
                          هي أداة رقمية تمكن من معرفة التفاصيل المتعلقة بالأطفال
                          من حيث الاسم ، والعمر ، والدرجة ، والحالة الصحية ،
                          وفصيلة الدم ، وعنوان المنزل ، ورقم الهاتف المرجعي ،
                          ونوع الأدوية المتعلقة بالطفل ، والإجراءات الأولية
                          المتبعة في حدوث نوبات لدى الطفل وهذا يساعد على تقليل
                          معدل الخطأ في التعامل مع الأطفال ويزيد من سهولة التعرف
                          على الطفل في المدارس والأماكن العامة.
                        </p>
                      </div>
                      <div className="col-lg-12 img-mboile-link">
                        <div className="">
                          <a href="https://play.google.com/store/apps/details?id=com.sewarajo.sewara">
                            <img src={GP} alt="" />
                          </a>
                        </div>
                        <div className="">
                          <a href="#contact">
                            <img src={AP} alt="" />
                          </a>
                        </div>
                        <div className="">
                          <a href="#contact">
                            <img src={HW} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="right-image wow fadeInRight"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                  >
                    <img
                      src="assets/images/scanner-removebg-preview.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

       <div id="services" className="services section">
        <div className="container" style={{margin:'0 auto'}}>
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div
                className="section-heading  wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <h4>
                  <em>CONTACT US</em>  
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{margin:'0 auto'}}>
          <div className="row">
            <div className="col-lg-4">
              <div className="service-item fourth-service">
                <div className="icon"></div>
                <h4>info.sewara-jo.com</h4>
                <p>
                Contact us via Email
                </p>
                <div className="text-button">
                  <a href="mailto:info.sewara-jo.com">
                    Contact Now <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-item fourth-service">
                <div className="icon"></div>
                <h4>+962 7993 84891</h4>
                <p>
                Contact us via Phone
                </p>
                <div className="text-button">
                  <a href="tel:00962799384891">
                    Contact Now <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-item fourth-service">
                <div className="icon"></div>
                <h4>+962 7993 84891</h4>
                <p>
                Order Sewara in WhatsApp 
                </p>
                <div className="text-button">
                  <a href="https://api.whatsapp.com/send?phone=00962799384891&text=Hello Sewara" target="_blank">
                    Order Now <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
       
         
          </div>
        </div>
      </div>
      <Footer />
    </div>
    </>
  );
};

export default Home;
