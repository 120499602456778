import React, { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import logo from "../../assets/imgs/logonew.png";
import "./style.css";
import Spinner from "../../components/Spinner";
import { Link } from "react-router-dom";
const ChildrenQR = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorObj, setErrorObj] = useState(null);
  const [location, setLocation] = useState(null);
  // var latitude = null;
  // var longitude = null;
  useEffect(() => {

    if (!location){
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
  
            setLocation({ latitude, longitude });
          },
          (error) => {
            setLocation({ latitude: null, longitude: null });
            console.log(error.message);
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser");
      }
    }

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}childs/checkQR`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              qrCode: window.location.href,
              // qrCode:
              //   "https://staging.sewara-jo.com/child/2b843a98-9e30-4091-a723-82505592b6c4",
            }),
          }
        );

        if (!response.ok) {
          const errorMessage = await response.json();
          setErrorObj(errorMessage);
          throw new Error(errorMessage);
        }

        const result = await response.json();
        console.log('result :>> ', result);
        await fetch(
          `${process.env.REACT_APP_API_URL}send/notification`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              qrCode: window.location.href,
              // qrCode:
              //   "https://staging.sewara-jo.com/child/2b843a98-9e30-4091-a723-82505592b6c4",
              id: result.userID,
              body: `SendNotification App ${location.latitude} && ${location.longitude}`,
              title: 'SendNotification user',
              lat: location.latitude,
              long: location.longitude,
            }),
          }
        );
        setData(result);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    if (location){
      fetchData();
    }
  }, [location]);

  function formatDate(inputDateString) {
    const dateObject = new Date(inputDateString);

    // Extract components
    const month = dateObject.getMonth() + 1; // Months are zero-based
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();

    // Format as MM/DD/YYYY
    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate;
  }

  if (loading || !location) {
    return <Spinner />;
  }

  // if (error) {
  //   return <p>Error: {error.message}</p>;
  // }

  return (
    <>
      <div className="head-title">
        <img src={logo} alt="Sewarah_QR" className="sewara-child-logo" />
        <p className="p-war ">
          الطفل أمانة في عنقك الرجاء التواصل مع أحد الأشخاص المعلوماتهم بالأسفل
          وفي حال عدم الإستجابة التواصل معنا شاكرين لك حسن تعاونك
        </p>
        <p>
          وفي حال تعرض الطفل الى أي أعراض يرجى اتباع اولويات الصحة المتبعة الى
          حين وصول الإسعاف
        </p>
      </div>
      <div className=" card-page">
        <div className="card">
          {error ? (
            <p className="text-red-child">
              {errorObj?.message
                ? errorObj?.message
                : "oops we have a problem please try again"}
            </p>
          ) : (
            <>
              <div className="row">
                {data.profilePicture ? (
                  <div className="text-center qr-images">
                    <img
                      src={data.profilePicture}
                      alt="userProfile"
                      className="rounded qr-images-show"
                    />
                  </div>
                ) : null}
              </div>
              <div className="row">
                <div className="col-md-4 col-12 sec-card">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Name :</label>
                    <input
                      type="text"
                      value={data.name}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4 col-12 sec-card">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">School Name :</label>
                    <input
                      type="text"
                      value={data.schoolName}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4 col-12 sec-card">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Blood Type :</label>
                    <input
                      type="text"
                      value={data.bloodType}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-3 col-12 sec-card">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">City :</label>
                    <input
                      type="text"
                      value={data.city}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-2 col-12 sec-card">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Area :</label>
                    <input
                      type="text"
                      value={data.area}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3 col-12 sec-card">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Street Name :</label>
                    <input
                      type="text"
                      value={data.streetName}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-2 col-12 sec-card">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Building Number :
                    </label>
                    <input
                      type="text"
                      value={data.buildingNumber}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-2 col-12 sec-card">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Special Marque :</label>
                    <input
                      type="text"
                      value={data.specialMarque}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-3 col-12 sec-card">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Father Name :</label>
                    <input
                      type="text"
                      value={data.fatherName}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3 col-12 sec-card">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Father Mobile :</label>
                    <input
                      type="text"
                      value={data.fatherPhone}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3 col-12 sec-card">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Mother Name :</label>
                    <input
                      type="text"
                      value={data.motherName}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3 col-12 sec-card">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Mother Mobile :</label>
                    <input
                      type="text"
                      value={data.motherPhone}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-4 col-12 sec-card">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Children Diseases :
                    </label>
                    <input
                      type="text"
                      value={data.childrenDiseases}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4 col-12 sec-card">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Safety Measures :
                    </label>
                    <input
                      type="text"
                      value={data.safetyMeasures}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4 col-12 sec-card">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Birth Date :</label>
                    <input
                      type="text"
                      value={formatDate(data.birthDate)}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="footer-child">
        <a href={"/"} className="btn-home-child">
          <i className="fa fa-sign-in-alt"></i> Go Home
        </a>
      </div>
    </>
  );
};

export default ChildrenQR;
