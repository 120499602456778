import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Root/Header";

const RootLayout = () => {
  return (
    <div>
      <Header />
      <Outlet key={Date.now()} />
    </div>
  );
};

export default RootLayout;
