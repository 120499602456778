import React from "react";
import QRCode from "react-qr-code";
import "./index.css";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
const CreateQR = ({ props }) => {
  const Data = [
    {
      id: 1,
      qr: "123456789",
    },
    {
      id: 2,
      qr: "jj98876543345678",
    },
    {
      id: 3,
      qr: "hhh;5555",
    },
    {
      id: 4,
      qr: "jjggl5555j888",
    },
    {
      id: 5,
      qr: "255455225424",
    },
    {
      id: 6,
      qr: "222225585d1dd1d5d1d1d255455225424",
    },
  ];
  const qrMap = Data.map((item) => (
    <tr key={item.id}>
      <td class="border px-8 py-4">{item.qr}</td>
      <QRCode
        className="border px-8 py-4"
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={item.qr}
        viewBox={`0 0 256 256`}
      />
    </tr>
  ));
  const downloadPdf = () => {
    // const pdf = new jsPDF();
    // pdf.autoTable({ html: "#myQR" });
    // pdf.save("table.pdf");
  };
  return (
    <table class="shadow-lg bg-white m-3 mobile" id="myQR">
      <button onClick={() => {}}>Download</button>
      <tr>
        <th class="bg-blue-100 border text-left px-8 py-4">QR ID</th>
        <th class="bg-blue-100 border text-left px-8 py-4">QR</th>
      </tr>
      {qrMap}
    </table>
  );
};

export default CreateQR;
