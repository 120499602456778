import React, { useState } from "react";
import logo from "../../assets/icons/logo.png";
import { Link } from "react-router-dom/dist";

const SubMenu = () => {
  return (
    <div className="subMenuBox">
      <ul>
        <li className="sub-section ">
          <Link to="/" className="active">
            Home
          </Link>
        </li>
        <li className="sub-section ">
          <Link to="/privacyPolicy" className="active">
            Privacy & Policy
          </Link>
        </li>
        <li className="sub-section ">
          <Link to="/terms-and-condition" className="active">
            Our Terms
          </Link>
        </li>
      </ul>
    </div>
  );
};
const Header = () => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  return (
    <header
      className="header-area header-sticky wow slideInDown"
      data-wow-duration="0.75s"
      data-wow-delay="0s"
    >
      <div className="container" style={{ margin: "0 auto" }}>
        <div className="row">
          <div className="col-12">
            <nav className="main-nav">
              <Link to="/" className="logo">
                <img src="assets/images/logonew.png" alt="Sewara logo" />
              </Link>

              <ul className="nav">
                <li className="scroll-to-section ">
                  <Link to="/" className="active">
                    Home
                  </Link>
                </li>
                <li className="scroll-to-section ">
                  <Link to="/privacyPolicy" className="active">
                    Privacy & Policy
                  </Link>
                </li>
                <li className="scroll-to-section ">
                  <Link to="/terms-and-condition" className="active">
                    Our Terms
                  </Link>
                </li>
                {/* <li className="scroll-to-section"><a href="#services">Services</a></li>
              <li className="scroll-to-section"><a href="#about">About</a></li> */}
                {/* <li><div className="gradient-button"><Link to='/login' id="modal_trigger"><i className="fa fa-sign-in-alt"></i> Login</Link></div></li>  */}
              </ul>
              <a className="menu-trigger" onClick={()=>setOpenSubMenu(!openSubMenu)} >
                <span>Menu</span>
              </a>
            </nav>
          </div>
        </div>
      </div>

      {
        openSubMenu && <SubMenu/>
      }
    </header>
  );
};

export default Header;
