import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./globalStyle.css";
import LayoutDashboard from "./layout/Dashboard";
import Wrapper from "./components/Wrapper/Wrapper";
import HomePageNew from "./pages/HomePageNew";
import CreateQrPage from "./pages/CreateQRPage/CreateQrPage";
import RootLayout from "./layout/Root";
import Home from "./pages/Root/Home";
import NotFound from "./pages/NotFound";
import TermsAndCondition from "./pages/terms_and_condition";
import ChildrenQR from "./pages/ChildrenQR";
import Login from "./pages/Login";
import PrivacyPolicy from "./pages/Privacy_policy";
import ShowAllQR from "./pages/showAllQR";
import ShowQRUsed from "./pages/ShowQRUsed";
import Spinner from "./components/Spinner";
import ShowQRNotUsed from "./pages/ShowQRNotUsed";

const isAuthenticated = async () => {
  const token = localStorage.getItem("auth_token");

  if (!token || token.length === 0) {
    return false;
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}admin/checkToken`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify({}),
      }
    );

    if (response.ok) {
      // Token is valid
      return true;
    } else {
      // Token is invalid
      return false;
    }
  } catch (error) {
    console.error("Error validating token:", error);
    return false;
  }
};

const PrivateRoute = ({ element, ...props }) => {
  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuthentication = async () => {
      const result = await isAuthenticated();
      setAuthenticated(result);
    };

    checkAuthentication();
  }, []);

  if (authenticated === null) {
    return <Spinner />;
  }

  return authenticated ? (
    element
  ) : (
    <Navigate to="/login" replace state={{ from: props.location }} />
  );
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RootLayout />} exact>
          <Route index element={<Home />} />
          <Route path="terms-and-condition" element={<TermsAndCondition />} />
          <Route path="privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="login" element={<Login />} />
        </Route>
        <Route
          path="/dashboard"
          element={<PrivateRoute element={<LayoutDashboard />} />}
        >
          <Route path="" element={<HomePageNew />} />
          <Route path="wrapper" element={<Wrapper />} />
          <Route path="create/qr" element={<CreateQrPage />} />
          <Route path="show/qr" element={<ShowAllQR />} />
          <Route path="show/qr/used" element={<ShowQRUsed />} />
          <Route path="show/qr/not-used" element={<ShowQRNotUsed />} />
          {/* <Route path="tasks" element={<DashboardTasks />} /> */}
        </Route>
        <Route path="child/:id" element={<ChildrenQR />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
