import React from 'react'
import { Link } from 'react-router-dom'
import notFound from '../../assets/imgs/404-notfound.png'
import './style.css'
const NotFound = () => {
  return (
    <div className='not-found-page'>
      <div className='not-found-page-img'>
        <img src={notFound} alt='not-found'/>
      </div>
      <div className="gradient-button not-found-page-btn"><Link to='/' id="modal_trigger"><i className="fa fa-sign-in-alt"></i> Go Home</Link></div>
      </div>
  )
}

export default NotFound