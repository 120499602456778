import React from "react";
import "./Sidebar.css";
import { FaChartBar, FaGraduationCap, FaPlane, AiTwotoneEyeInvisible,FaQrcode,   } from "react-icons/fa";
import {
  AiFillBell,
  AiFillNotification,
  AiOutlineSetting,
  AiFillDatabase
} from "react-icons/ai";
import { GrProjects } from "react-icons/gr";
import { CiViewList } from "react-icons/ci";
import Wrapper from "../Wrapper/Wrapper";
import { BsBookmarkX } from "react-icons/bs";
import { Link } from "react-router-dom";
const Sidebar = ({ onClick }) => {
  return (
    <div className="page">
      <div className="sidebar">
        <h3 className="main-title">Sewara</h3>
        <ul>
          <li>
            <Link
              to={"/dashboard"}
              // onClick={()=>onClick('second')}

              className="active sidebar-link"
            >
              <FaChartBar className="fa-fw" />
              <span>DashBoard</span>
            </Link>
          </li>
          {/* <li>
            <Link
              to={"/wrapper"}
              // onClick={()=>onClick('value')}
              className="active sidebar-link"
              // href="#"
            >
              <AiOutlineSetting className="fa-fw" />
              <span>Settings</span>
            </Link>
          </li> */}
          {/* <li>
            <Link
              to={"/"}
              className="active sidebar-link"
              // href="#"
            >
              <FaUser className="fa-fw" />
              <span>Profile</span>
            </Link>
          </li> */}
          {/* <li>
            <Link
              to={"/"}
              className="active sidebar-link"
              // href="#"
            >
              <GrProjects className="fa-fw" />
              <span>Project</span>
            </Link>
          </li> */}
          {/* <li>
            <Link
              to={"/"}
              className="active sidebar-link"
              // href="#"
            >
              <FaGraduationCap className="fa-fw" />
              <span>Courses</span>
            </Link>
          </li> */}
          <li>
            <Link
              to={"/dashboard/create/qr"}
              className="active sidebar-link"
              // href="#"
            >
              <FaQrcode className="fa-fw" />
              <span>Create QR</span>
            </Link>
          </li>
          <li>
            <Link
              to={"/dashboard/show/qr"}
              className="active sidebar-link"
              // href="#"
            >
              <AiFillDatabase className="fa-fw" />
              <span>Show All QR</span>
            </Link>
          </li>
          <li>
            <Link
              to={"/dashboard/show/qr/used"}
              className="active sidebar-link"
              // href="#"
            >
              <CiViewList className="fa-fw" />
              <span>SHOW QR USED</span>
            </Link>
          </li>
          <li>
            <Link
              to={"/dashboard/show/qr/not-used"}
              className="active sidebar-link"
              // href="#"
            >
              <BsBookmarkX className="fa-fw" />
              <span>SHOW QR NOT USED</span>
            </Link>
          </li>
        </ul>
      </div>
      {/* <div className="content w-full">
        <div className="head bg-white p-4 between-flex">
          <div className="search relative">
            <input className="p-3" type="text" placeholder="Type A Keyword" />
          </div>
          <div className="icons flex items-center">
            <span className="notification relative fa-lg">
              <AiFillBell />
            </span>
            <img src={require("../../assets/imgs/avatar.png")} alt="" />
          </div>
        </div>
        <h1 className="relative my-5 ml-4 Title-head">Dashboard</h1>
        <Wrapper />
      </div> */}
    </div>
  );
};

export default Sidebar;
