import React, { useMemo, useState } from "react";
import QRCode from "react-qr-code";
import CreateQR from "../../components/CreateQR/CreateQR";
import "./index.css";
import { useTable } from "react-table";
import Spinner from "../../components/Spinner";
import { useReactToPrint } from "react-to-print";

const CreateQrPage = () => {
  const [count, setCount] = useState(50);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const tableRef = React.useRef();

  const ImageCell = ({ value }) => {
    console.log("Base64 Value:", value);
    return (
      <img
        src={value}
        alt="QR Code"
        style={{ width: "50px", height: "50px" }}
      />
    );
  };
  const columns = useMemo(
    () => [
      { Header: "QR Code", accessor: "qrCode" },
      { Header: "Image", accessor: "image", Cell: ImageCell },
    ],
    []
  );
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });
  const handleCountChange = (e) => {
    setCount(e.target.value);
  };
  const handleCreateQR = () => {
    setIsLoading(true);
    setData([]);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      value: count,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://staging.sewara-jo.com/api/qr/create", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("result handleCreateQR", result.qrCode);
        // setData(result.qrCode);
        setData(result.qrCode);

        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error handleCreateQR", error);
        setIsLoading(false);

        window.alert(error);
      });
  };

  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(() => data, [data]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: memoizedColumns,
      data: memoizedData,
    });
  return (
    <div>
      {/* <CreateQR /> */}
      <div className="container">
        <div className="create-section">
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              Count OF QR{" "}
            </label>
            <input
              type="number"
              class="form-control"
              id="username"
              value={count}
              onChange={handleCountChange}
              disabled={true}
            />
          </div>
          <button
            onClick={(e) => handleCreateQR()}
            type="submit"
            class="btn-create"
          >
            Create QR
          </button>{" "}
          {data.length ? (
            <button className="btn-print" onClick={handlePrint}>Print Table to PDF</button>
          ) : null}
        </div>
        {data.length ? (
          <>
          <table {...getTableProps()} ref={tableRef} className="min-w-full overflow-x-auto">
  <thead>
    {headerGroups.map((headerGroup) => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <th {...column.getHeaderProps()} className="py-2 px-4">
            {column.render("Header")}
          </th>
        ))}
      </tr>
    ))}
  </thead>
  <tbody {...getTableBodyProps()}>
    {rows.map((row) => {
      console.log("row.cells :>> ", row);
      prepareRow(row);
      return (
        <tr {...row.getRowProps()}>
          {row.cells.map((cell) => (
            <td {...cell.getCellProps()} className="py-2 px-4">
              {cell.column.id === "qrCode"
                ? cell.value // Render value if present
                : null}
              {cell.column.id === "image" ? (
                <img
                  src={cell.value}
                  alt="QR Code"
                  style={{
                    maxWidth: "100%",
                    height: "150px",
                    width: "150px",
                  }}
                />
              ) : null}
              {/* Show loading message or handle appropriately */}
            </td>
          ))}
        </tr>
      );
    })}
  </tbody>
</table>
          </>
        ) : null}
        {isLoading ? <Spinner /> : null}
      </div>
    </div>
  );
};

export default CreateQrPage;
