import React from "react";
import "./index.css";
export default function PrivacyPolicy() {
  return (
    <div className="main">
      <div className="container">
        <h1 className="terms-title" style={{ paddingTop: "9rem" }}>
          Terms and Conditions
        </h1>
        <p>Last updated: November 18, 2023</p>
        <p>
          Please read these Privacy Policy carefully before using Our Service.
        </p>
        <h2>Interpretation and Definitions :</h2>
        <p>
          Collecting information: User information such as location and personal
          data related to the child may be collected to provide “Siwara”
          application services. Use of Information: The information collected
          will be used to provide the Services and user experience, including
          location identification and parent communication in accordance with
          the Terms of Service. Sharing Information: We will not share users'
          information with parties without their consent, unless required to
          provide services or required externally by law. Access rights: They do
          not have the right to access, correct or delete their personal
          information, in accordance with applicable laws. Security Information:
          We are committed to protecting the confidentiality and security of
          users' information from unauthorized access, use, development or
          deletion. Privacy Policy Change: We will keep the change updated or
          change the privacy policy, and users will be notified of the new
          information.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about these Privacy Policy, You can
          contact us:
        </p>
        <ul>
          <li>
            <a href="mailto:info@sewara-jo.com">By email: info@sewara-jo.com</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

